import { useState } from 'react';
import { toaster } from 'baseui/toast';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { CURRENCY_MAP } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { isValidResponseObject } from 'shared/helpers';

import { API } from 'store/AccountSettings/api';
import { fontStyleSelector } from 'store/App/User';
import { RootState } from 'store/rootReducer';
import { customerIdSelector } from 'store/Users/selectors';

import { getAgendaType } from '../helpers/helpers';

import ConfirmationModal from './ConfirmationModal';
import DateTimeModal from './DateTimeModal';
import ExpertAssistanceModal, { IMessageType } from './ExpertAssistanceModal';
import HelpYourselfModal from './HelpYourselfModal';
import PaymentFailureModalExpertAssistance from './PaymentFailureModalExpertAssistance';

const ExpertAssistanceForm = ({
	toggleModal,
	setDateValue,
	dateValue,
}: {
	toggleModal: Function;
	setDateValue: (date: Date) => void;
	dateValue: Date;
}) => {
	const { t } = useTranslation(['pages']);

	const [selectedMessageType, setSelectedMessageType] = useState<IMessageType>();
	const [openDateTime, setOpenDateTime] = useState<boolean>(false);
	const [openHelpYourself, setOpenHelpYourself] = useState<boolean>(false);

	const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
	const [openExpertAssistance, setOpenExpertAssistance] = useState<boolean>(true);
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const [agendaAmounts, setAgendaAmounts] = useState<IObjProps>({});

	const [timezone, setTimezone] = useState<string>('');
	const currency = useSelector((state: RootState) => state.Plan.current.data?.currencyName) || CURRENCY_MAP.USD;
	const cid = useSelector(customerIdSelector);
	const fontStyle = useSelector(fontStyleSelector);

	const defaultValues = { messageType: null };
	const methods = useForm<any>({ mode: 'onChange', defaultValues });
	const { reset, getValues, control } = methods;
	useWatch({
		control,
		name: ['additionalComments'],
	});

	const handleNext = (nextModalSetter: Function) => {
		setOpenHelpYourself(false);
		setOpenExpertAssistance(false);
		setOpenDateTime(false);
		setOpenConfirmationModal(false);
		nextModalSetter(true);
	};

	const fetchDataAndConfirm = async () => {
		try {
			const response = await API.getAgendaDetails(currency);

			if (isValidResponseObject(response)) {
				const amounts = response.data.responseObject.reduce((acc: IObjProps, item: IObjProps) => {
					acc[item.agendaId] = item.amount;

					return acc;
				}, {});

				setAgendaAmounts(amounts);

				handleNext(setOpenConfirmationModal);
			} else {
				errorToaster();
			}
		} catch (error) {
			errorToaster();
		}
	};
	const errorToaster = () => {
		toaster.negative(
			<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
			{}
		);
	};

	const errorFn = () => {
		setModalOpen(true);
		errorToaster();
	};

	const handleSubmitForm = () => {
		const data = {
			slotStartTime: dateValue.toISOString(),
			currentUrl: `${window.location.href}`,
			agenda: selectedMessageType && getAgendaType([selectedMessageType]),
			agendaName: selectedMessageType?.label,
			phoneNumber: getValues('userPhoneNo'),
			additionalEmail: getValues('communicationEmail'),
			assistanceDescription: getValues('additionalComments'),
		};

		API.submitExpertAssistanceRequest(data)
			.then((response: any) => {
				if (isValidResponseObject(response)) {
					localStorage.setItem(`${cid}-dateValue`, dateValue.toISOString());
					window.location.href = response.data.responseObject;
				} else {
					errorFn();
				}
			})
			.catch((error: any) => {
				errorFn();
			});

		reset();
	};

	return (
		<FormProvider {...methods}>
			<ExpertAssistanceModal
				isOpen={openExpertAssistance}
				onClose={() => toggleModal(false)}
				onConfirm={() => handleNext(setOpenHelpYourself)}
				selectedMessageType={selectedMessageType}
				setSelectedMessageType={setSelectedMessageType}
			/>
			<HelpYourselfModal
				isOpen={openHelpYourself}
				onClose={() => toggleModal(false)}
				onConfirm={() => handleNext(setOpenDateTime)}
				selectedMessageType={selectedMessageType}
				setSelectedMessageType={setSelectedMessageType}
				timezone={timezone}
				setTimezone={setTimezone}
				fontStyle={fontStyle}
			/>
			<ConfirmationModal
				isOpen={openConfirmationModal}
				onClose={() => handleNext(setOpenDateTime)}
				onConfirm={() => handleNext(handleSubmitForm)}
				onModalClose={() => toggleModal(false)}
				selectedMessageType={selectedMessageType}
				agendaAmounts={agendaAmounts}
				dateValue={dateValue}
			/>
			<DateTimeModal
				isOpen={openDateTime}
				onConfirm={() => fetchDataAndConfirm()}
				onClose={() => handleNext(setOpenHelpYourself)}
				onModalClose={() => toggleModal(false)}
				dateValue={dateValue}
				setDateValue={setDateValue}
			/>
			<PaymentFailureModalExpertAssistance
				toggleModal={setModalOpen}
				isModalOpen={isModalOpen}
				setOpenConfirmationModal={setOpenConfirmationModal}
			/>
		</FormProvider>
	);
};

export default ExpertAssistanceForm;
